<template>
  <div class="inputOption" :class="editOption ? 'editMode' : ''">
    <div v-if="!editOption" class="card">
      <div class="card-body">
        <b-form-group
          :label-for="'option' + label + (optionIndex + 1)"
          class="d-flex align-items-center custom-form-input-for-options"
          :label="$t(label) + ' #' + (optionIndex + 1)"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Delete')"
            variant="flat-primary"
            class="btn-icon ml-auto"
            @click="deleteOption"
          >
            <feather-icon size="20" icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Edit')"
            variant="flat-primary"
            class="btn-icon editOptionFromParent"
            @click="openEdit"
          >
            <feather-icon size="20" icon="EditIcon" />
          </b-button>
        </b-form-group>
        <div class="d-flex">
          <label class="mr-1 align-self-center cursor-pointer" @click="openEdit"
            >{{ value.from_date }} -
            {{ value.to_date }}
            <span v-if="value.attached_price"
              >&nbsp;&nbsp;&nbsp; · &nbsp;&nbsp;&nbsp;{{
                $t('Attached price') + ': ' + value.attached_price
              }}</span
            >
          </label>
        </div>
      </div>
    </div>
    <div v-if="editOption" class="card">
      <div class="card-body">
        <b-form-group
          :label-for="'option' + label + (optionIndex + 1)"
          class=""
          :label="$t(label) + ' #' + (optionIndex + 1)"
        >
          <date-picker
            v-model="range"
            format="DD/MM/YYYY"
            type="date"
            class="w-100 custom-placeholder"
            range
            :class="emptyValue ? 'border-danger-for-time-picker' : ''"
            value-type="format"
            :lang="{
              formatLocale: {
                firstDayOfWeek: 1,
              },
              monthBeforeYear: false,
            }"
            :editable="false"
            :clearable="false"
            :placeholder="
              $t('Select the start date and end date of each camp period (week or other)')
            "
            @blur="touched = true"
          />
          <small v-if="emptyValue" class="text-danger"
            >{{ $t(label) }} {{ $t("can't be empty") }}</small
          >
        </b-form-group>
        <div class="d-flex">
          <b-button
            v-if="value.to_date"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="editOption = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-if="!value.to_date"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="deleteOption"
          >
            {{ $t('Delete') }}
          </b-button>
          <b-button
            ref="saveBtn"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1"
            variant="primary"
            :disabled="disableSave"
            @click="saveNewValue"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BButton, VBTooltip } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@/components/ToastificationContent'

export default {
  name: 'InputOption',
  components: {
    BFormGroup,
    BButton,
    DatePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array,
      default: [],
    },
    value: {
      type: Object,
      default: () => {
        return { from_date: null, to_date: null, attached_price: null }
      },
    },
    optionIndex: {
      type: Number,
      default: null,
    },
    optionsLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editOption: false,
      attachedPrice: null,
      touched: false,
      newValue: { from_date: null, to_date: null, attached_price: null },
    }
  },
  computed: {
    pricesOptions: function () {
      return this.prices.map(el => {
        return {
          code: el.price,
          label: el.price + '€ - ' + el.price_unit,
        }
      })
    },
    range: {
      get() {
        return [this.newValue.from_date, this.newValue.to_date]
      },
      set(newRange) {
        this.newValue.from_date = newRange[0]
        this.newValue.to_date = newRange[1]
        return [this.newValue.from_date, this.newValue.to_date]
      },
    },
    emptyValue() {
      return !this.newValue.to_date && this.touched
    },
    disableSave() {
      return !this.newValue.to_date
    },
  },
  watch: {
    newValue(newVal, oldVal) {
      this.touched = true
    },
    prices: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    openEdit() {
      this.editOption = true
      this.touched = false
      this.newValue.from_date = this.value.from_date
      this.newValue.to_date = this.value.to_date
      this.newValue.attachedPrice = this.value.attached_price
    },
    saveNewValue() {
      let payload = {
        ...this.newValue,
        attached_price: this.attachedPrice?.label || null,
      }
      this.$emit('optionChanged', payload)
      this.attachedPrice = null
      this.editOption = false
    },
    deleteOption() {
      if (this.optionsLength === 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: 'AlertTriangleIcon',
            text: this.$t('You should have at least 1 calender option'),
            variant: 'danger',
          },
        })
        return
      }
      this.$emit('deleteOption', this.optionIndex)
    },
  },
}
</script>

<style scoped>
.inputOption .form-control[readonly] {
  background-color: unset;
}

.inputOption .btn-icon {
  padding: 0.5rem;
}

.inputOption .btn-icon:last-of-type {
  margin-right: -0.5rem;
}

.card {
  box-shadow: 0 4px 24px 0 rgb(14 26 26 / 10%) !important;
}

.custom-form-input-for-options {
  font-weight: 600;
}

::v-deep .custom-placeholder input::placeholder {
  font-size: 12px;
}
</style>

<template>
  <div class="inputOption" :class="editOption ? 'editMode' : ''">
    <div v-if="!editOption" class="card">
      <div class="card-body">
        <b-form-group
          :label-for="'option' + label + (optionIndex + 1)"
          class="d-flex align-items-center custom-form-input-for-options"
          :label="$t(label) + ' #' + (optionIndex + 1)"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Delete')"
            variant="flat-primary"
            class="btn-icon ml-auto"
            @click="deleteOption"
          >
            <feather-icon size="20" icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Edit')"
            variant="flat-primary"
            class="btn-icon editOptionFromParent"
            @click="openEdit"
          >
            <feather-icon size="20" icon="EditIcon" />
          </b-button>
        </b-form-group>
        <div class="d-flex flex-column">
          <div class="">
            <label class="card-label">{{ $t('From') }} :</label>
            <label class="ml-1">{{ value.from_time }}</label>
          </div>
          <div class="">
            <label class="card-label">{{ $t('To') }} :</label>
            <label class="ml-1">{{ value.to_time }}</label>
          </div>
          <div v-if="value.attached_price">
            <label class="card-label">{{ $t('Attached price') }} :</label>
            <label class="ml-1">{{ value.attached_price }}</label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editOption" class="card">
      <div class="card-body">
        <div class="d-flex flex-column flex-md-row">
          <b-form-group
            :label-for="'option' + label + (optionIndex + 1)"
            class="mr-3 d-flex"
            style="gap: 20px"
            :label="$t('From')"
          >
            <date-picker
              id="from-date"
              v-model="newValue.from_time"
              format="HH:mm"
              :minute-step="5"
              :disabled-time="disableFromTime"
              type="time"
              value-type="format"
              :class="emptyValue.from_time ? 'border-danger-for-time-picker' : ''"
              :editable="false"
              :clearable="false"
              @blur="touched.from_time = true"
            />
            <small v-if="emptyValue.from_time" class="d-flex text-danger"
              >{{ $t('this field') }} {{ $t("can't be empty") }}</small
            >
          </b-form-group>

          <b-form-group
            :label-for="'option' + label + (optionIndex + 1)"
            class="d-flex"
            style="gap: 20px"
            :label="$t('To')"
          >
            <date-picker
              id="to-time"
              v-model="newValue.to_time"
              format="HH:mm"
              :minute-step="5"
              :disabled-time="disableToTime"
              type="time"
              value-type="format"
              :editable="false"
              :class="emptyValue.to_time ? 'border-danger-for-time-picker' : ''"
              :clearable="false"
              @blur="touched.to_time = true"
            />
            <small v-if="emptyValue.to_time" class="d-flex text-danger"
              >{{ $t('this field') }} {{ $t("can't be empty") }}</small
            >
          </b-form-group>
        </div>
        <b-form-group
          v-if="false"
          :label-for="'prices_s' + (optionIndex + 1)"
          class=""
          :label="$t('Assigned price')"
        >
          <v-select
            id="price-type"
            v-model="attachedPrice"
            multiple
            :placeholder="$t('Price')"
            :options="pricesOptions"
          />
        </b-form-group>
        <div class="d-flex">
          <b-button
            v-if="value.from_time !== null && value.to_time !== null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="editOption = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-if="value.from_time === null && value.to_time === null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="deleteOption"
          >
            {{ $t('Delete') }}
          </b-button>
          <b-button
            ref="saveBtn"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1"
            variant="primary"
            :disabled="disableSave"
            @click="saveNewValue"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@/components/ToastificationContent'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'ActivityScheduleOptions',
  components: {
    BFormGroup,
    BButton,
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array,
      default: [],
    },
    value: {
      type: Object,
    },
    optionIndex: {
      type: Number,
      default: null,
    },
    optionsLength: {
      type: Number,
      default: 0,
    },
    daysList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      editOption: this.isOpen,
      attachedPrice: null,
      touched: {
        from_time: false,
        to_time: false,
      },
      defaultData: {
        id: -1,
        from_time: null,
        to_time: null,
      },
      newValue: null,
      initValue: null,
    }
  },
  computed: {
    pricesOptions: function () {
      return this.prices.map(el => {
        return {
          code: el.price,
          label: el.price + '€ - ' + el.price_unit,
        }
      })
    },
    emptyValue() {
      const checkEmpty = {
        from_time: false,
        to_time: false,
      }
      const keys = Object.keys(this.newValue)
      for (const key of keys) {
        if (key === 'id') continue
        if (!this.newValue[key] && this.touched[key]) {
          checkEmpty[key] = true
        }
      }
      return checkEmpty
    },
    disableSave() {
      let isEmpty = false
      let isChanged = false
      const keys = Object.keys(this.newValue)
      for (const key of keys) {
        if (key === 'id') continue
        if (!this.newValue[key]) {
          isEmpty = true
        } else if (this.newValue[key] !== this.value[key]) {
          isChanged = true
        }
      }
      return isEmpty || !isChanged
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.newValue.id = newVal.id
      this.newValue.from_time = newVal.from_time
      this.newValue.to_time = newVal.to_time
    },
  },
  mounted() {
    this.newValue = { ...this.defaultData }
  },
  methods: {
    openEdit() {
      this.editOption = true
      this.touched.from_time = false
      this.touched.to_time = false
      this.newValue.id = this.value.id
      this.newValue.from_time = this.value.from_time
      this.newValue.to_time = this.value.to_time
    },
    cancelEdit() {
      this.editOption = false
      if (this.newValue.id === -1) {
        this.$emit('deleteOption', +this.optionIndex)
      }
    },
    saveNewValue() {
      let payload = {
        ...this.newValue,
        attached_price: this.attachedPrice?.label || null,
      }
      this.$emit('optionChanged', payload)
      this.editOption = false
    },
    deleteOption() {
      if (this.optionsLength === 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: 'AlertTriangleIcon',
            text: this.$t('You should have at least 1 schedule option'),
            variant: 'danger',
          },
        })
        return
      }
      this.$emit('deleteOption', this.optionIndex)
    },
    disableFromTime(date) {
      if (this.newValue.to_time) {
        const newDate = new Date()
        newDate.setHours(
          this.newValue.to_time.split(':')[0],
          +this.newValue.to_time.split(':')[1] - 1
        )
        return date.getTime() > newDate.getTime()
      }
      return false
    },
    disableToTime(date) {
      if (this.newValue.from_time) {
        const newDate = new Date()
        newDate.setHours(
          this.newValue.from_time.split(':')[0],
          +this.newValue.from_time.split(':')[1] + 1
        )
        return date.getTime() < newDate.getTime()
      }
      return false
    },
  },
}
</script>

<style scoped>
.inputOption .form-control[readonly] {
  background-color: unset;
}

.inputOption .btn-icon {
  padding: 0.5rem;
}

.inputOption .btn-icon:last-of-type {
  margin-right: -0.5rem;
}

.card {
  box-shadow: 0 4px 24px 0 rgb(14 26 26 / 10%) !important;
}

.custom-form-input-for-options {
  font-weight: 600;
}

.card-label {
  min-width: 10%;
}
::v-deep .mx-datepicker {
  width: 120px;
}
</style>

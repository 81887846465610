<script>
import VueNumeric from 'vue-numeric'

const OPTIONS = {
  es: {
    currency: '€',
    decimals: '.',
    digits: 2,
    locale: 'es',
    'symbol-position': 'suffix',
  },
  en: {
    currency: '€',
    decimals: '.',
    digits: 2,
    locale: 'en',
    'symbol-position': 'prefix',
  },
}

export default {
  name: 'CurrencyInput',
  components: {
    VueNumeric,
  },
  props: {
    id: String,
    autofocus: Boolean,
    value: [Number, String],
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    options() {
      return { ...OPTIONS[this.$i18n.locale], min: 0 }
    },
    localValue: {
      get() {
        return this.value === '' ? this.value : this.value / 100
      },
      set(newVal) {
        this.$emit('input', newVal * 100)
      },
    },
  },
}
</script>

<template>
  <vue-numeric
    :id="id"
    v-model="localValue"
    type="text"
    :currency="options.currency"
    :decimal-separator-="options.decimals"
    :currency-symbol-position="options['symbol-position']"
    :precision="options.digits"
    :min="min"
    :placeholder="$t('Price')"
    :autofocus="autofocus"
    class="form-control"
    empty-value=""
  />
</template>

<style scoped></style>

<template>
  <div class="">
    <ActivityForm
      v-if="formData && activityType.id === 1"
      ref="activitiesForm"
      :form-data="formData"
      :item-id="itemId"
      :type-name="activityType.name"
      :form-title="`Edit ${activityType.name}`"
      :type-id="activityType.id"
      @formChanged="formChanged = $event"
    />
    <CampForm
      v-if="formData && activityType.id === 2"
      ref="activitiesForm"
      :form-data="formData"
      :item-id="itemId"
      :type-name="activityType.name"
      :form-title="`Edit ${activityType.name}`"
      :type-id="activityType.id"
      @formChanged="formChanged = $event"
    />
    <EventForm
      v-if="formData && activityType.id === 3"
      ref="activitiesForm"
      :form-data="formData"
      :item-id="itemId"
      :type-name="activityType.name"
      :form-title="`Edit ${activityType.name}`"
      :type-id="activityType.id"
      @formChanged="formChanged = $event"
    />
  </div>
</template>

<script>
import ActivityForm from '@/components/ActivityForm'
import EventForm from '@/components/EventForm'
import activityType from './activityType'
import ActivityService from '@/services/ActivityService'
import CampForm from '@/components/CampForm'

export default {
  name: 'Edit',
  components: {
    ActivityForm,
    CampForm,
    EventForm,
  },
  data() {
    return {
      itemId: +this.$route.fullPath.split('/').pop(),
      ActivityService: new ActivityService(),
      formChanged: false,
      formData: null,
      fromDate: null,
      name: '',
      activityType: {
        name: '',
        id: +this.$route.fullPath.split('/')[2],
      },
    }
  },
  mounted() {
    switch (this.activityType.id) {
      case activityType.ACTIVITY:
        this.activityType.name = 'activity'
        break
      case activityType.CAMP:
        this.activityType.name = 'camp'
        break
      case activityType.EVENT:
        this.activityType.name = 'event'
        break
    }
    this.getFormData()
  },
  methods: {
    getFormData() {
      this.ActivityService.show(this.itemId).then(res => {
        switch (this.activityType.id) {
          // Activity
          case 1:
            this.formData = {
              name: res.name,
              min_age: res.min_age,
              max_age: res.max_age,
              from_date: res.from_date,
              to_date: res.to_date,
              published: res.published,
              location_id: res.location_id,
              language_options: res.languages.map(lang => lang.id),
              category_options: res.categories.map(cat => cat.id),
              user_id: res.user_id,
              status: res.status,
              keywords: res.keywords,
              billable: res.billable,
              days: [],
              price_options: [],
              details: res.details,
              files: [],
              filesThumbnail: [],
            }
            res.media.forEach(m => {
              this.formData.files.push(m.id)
              this.formData.filesThumbnail.push({ id: m.id, original_url: m.original_url })
            })
            this.formData.days = res.days
            if (res.price_options) {
              res.price_options.forEach(option => {
                let pricing_model = option.settings?.pricing_model || 'one_off'
                this.formData.price_options.push({
                  id: option.id,
                  price: option.price ?? '',
                  price_unit: option.price_unit ?? '',
                  price_info: option.price_info ?? '',
                  display_price: option.display_price ?? '',
                  pricing_model,
                })
              })
            }
            break
          // Camp
          case 2:
            this.formData = {
              name: res.name,
              min_age: res.min_age,
              max_age: res.max_age,
              published: res.published,
              location_id: res.location_id,
              language_options: res.languages.map(lang => lang.id),
              category_options: res.categories.map(cat => cat.id),
              user_id: res.user_id,
              status: res.status,
              keywords: res.keywords,
              dates: [],
              times: [],
              price_options: [],
              billable: res.billable,
              details: res.details,
              files: [],
              filesThumbnail: [],
            }
            res.media.forEach(m => {
              this.formData.files.push(m.id)
              this.formData.filesThumbnail.push({ id: m.id, original_url: m.original_url })
            })
            if (res.dates) {
              res.dates.forEach(option => {
                this.formData.dates.push({
                  id: option.id,
                  from_date: option.from_date,
                  to_date: option.to_date,
                })
              })
            }
            if (res.times) {
              res.times.forEach(option => {
                let payload = {
                  id: option.id,
                  from_time: option.from_time,
                  to_time: option.to_time,
                }
                this.formData.times.push(payload)
              })
            }
            if (res.price_options) {
              res.price_options.forEach(option => {
                let schedules = option.schedules
                  ? option.schedules.split(',').map(el => ({
                      code: el,
                      label: el,
                    }))
                  : null
                this.formData.price_options.push({
                  id: option.id,
                  price: option.price ?? '',
                  price_unit: option.price_unit ?? '',
                  price_info: option.price_info ?? '',
                  display_price: option.display_price ?? '',
                  linked_elements: schedules ?? null,
                })
              })
            }
            break
          // Event
          case 3:
            this.formData = {
              name: res.name,
              min_age: res.min_age,
              max_age: res.max_age,
              from_time: res.from_time,
              to_time: res.to_time,
              from_date: res.from_date,
              to_date: res.to_date,
              published: res.published,
              location_id: res.location_id,
              language_options: res.languages.map(lang => lang.id),
              category_options: res.categories.map(cat => cat.id),
              user_id: res.user_id,
              status: res.status,
              keywords: res.keywords,
              price_options: [],
              billable: res.billable,
              custom_schedule: res.custom_schedule ? '1' : '0',
              custom_additional_info: res.custom_additional_info,
              details: res.details,

              files: [],
              filesThumbnail: [],
            }
            res.media.forEach(m => {
              this.formData.files.push(m.id)
              this.formData.filesThumbnail.push({ id: m.id, original_url: m.original_url })
            })
            if (res.price_options) {
              res.price_options.forEach(option => {
                this.formData.price_options.push({
                  id: option.id,
                  price: option.price ?? '',
                  price_unit: option.price_unit ?? '',
                  price_info: option.price_info ?? '',
                  display_price: option.display_price ?? '',
                })
              })
            }
            break
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged && !this.itemId) {
      this.$refs.activitiesForm.show = true
      this.$refs.activitiesForm.modalOptions = {
        clickedId: -1,
        text: this.$t(`Do you want to save this as a draft?`, {
          activityType: this.$t(this.activityType.name),
        }),
        okButton: this.$t('Yes, save draft'),
        cancelButton: this.$t('No, discard'),
        goToPath: to.name,
      }
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style scoped></style>

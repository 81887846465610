<template>
  <div class="inputOption" :class="editOption ? 'editMode' : ''">
    <div v-if="!editOption" class="card">
      <div class="card-body">
        <b-form-group
          :label-for="'option' + label + (optionIndex + 1)"
          class="d-flex align-items-center custom-form-input-for-options"
          :label="$t(label) + ' #' + (optionIndex + 1)"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Delete')"
            variant="flat-primary"
            class="btn-icon ml-auto"
            :disabled="value.price === '-1'"
            @click="deleteOption"
          >
            <feather-icon size="20" icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="$t('Edit')"
            variant="flat-primary"
            class="btn-icon editOptionFromParent"
            :disabled="value.price === '-1'"
            @click="openEdit"
          >
            <feather-icon size="20" icon="EditIcon" />
          </b-button>
        </b-form-group>
        <div class="d-flex flex-column">
          <div>
            <label class="card-label">{{ $t('Price') }} :</label>
            <label class="ml-1">{{ value.price === 0 ? $t('Free') : value.display_price }}</label>
          </div>
          <div>
            <label class="card-label">{{ $t('Price unit') }} :</label>
            <label class="ml-1">{{ $t(value.price_unit) }}</label>
          </div>
          <div v-if="value.linked_elements">
            <label class="card-label">{{ $t('Linked schedules') }} :</label>
            <label class="ml-1">{{ value.linked_elements.map(el => el.label).join(', ') }}</label>
          </div>
          <div v-if="value.pricing_model || value.settings">
            <label class="card-label">{{ $t('Pricing model') }} :</label>
            <label class="ml-1">{{ $t(value.pricing_model) }}</label>
          </div>
          <!--          price info is optional input now-->
          <div v-if="value.price_info">
            <label class="card-label">{{ $t('Price info') }} :</label>
            <label class="ml-1">{{ value.price_info }}</label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editOption" class="card">
      <div class="card-body">
        <b-form-group v-if="!isCampActivity" :label-for="'free-' + label + (optionIndex + 1)">
          <b-form-checkbox
            :id="'free-' + label + (optionIndex + 1)"
            v-model="free_or_paid"
            name="'free-'+label+(optionIndex+1)"
            value="free"
            unchecked-value="paid"
            switch
          >
            {{ $t('This pricing option is free') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="free_or_paid == 'paid'"
          :label-for="'option' + label + (optionIndex + 1)"
          :label="$t('Price')"
        >
          <CurrencyInput
            :id="'option' + label + (optionIndex + 1)"
            v-model="newValue.price"
            :min="isCampActivity ? 1 : 0"
          />
          <small v-if="emptyValue.price" class="text-danger"
            >{{ $t('Price') }} {{ $t("can't be empty") }}</small
          >
        </b-form-group>

        <b-form-group label-for="class-type" :label="$t('Unit')">
          <v-select
            id="class-type"
            v-model="newValue.price_unit"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('Unit')"
            :options="free_or_paid === 'paid' ? priceUnitList : freePriceUnitList"
            :clearable="false"
            :reduce="option => option.value"
            label="label"
            :class="emptyValue.price_unit ? 'border-danger-for-v-select' : ''"
            @keydown="touched.price_unit = true"
          />
          <small v-if="emptyValue.price_unit" class="text-danger"
            >{{ $t('Unit') }} {{ $t("can't be empty") }}</small
          >
        </b-form-group>

        <!-- setup price model in edition -->

        <b-form-group
          v-if="linkedToPrice.length && linkedToPrice[0].from_time"
          :label-for="'link_s' + (optionIndex + 1)"
          :label="$t('Assigned schedules')"
        >
          <v-select
            id="price-type"
            v-model="attachedLink"
            multiple
            :placeholder="$t('Assigned schedules')"
            :options="linkedElements"
          />
        </b-form-group>

        <b-form-group
          v-if="newValue.price_unit == ''"
          label-for="custom_unit"
          :label="$t('price_custom_label')"
        >
          <b-form-input
            :id="'option_cus' + label + (optionIndex + 1)"
            v-model="newValue.price_custom"
            :placeholder="$t('Two weeks, only afternoons')"
            @keypress.enter.prevent="$refs.saveBtn.click()"
            @keyup.esc="editOption = false"
            @keydown="touched.price_info = true"
          />
        </b-form-group>

        <b-form-group :label-for="'option' + label + (optionIndex + 1)" :label="$t('Price info')">
          <b-form-input
            :id="'option' + label + (optionIndex + 1)"
            v-model="newValue.price_info"
            :placeholder="$t('Price info')"
            @keypress.enter.prevent="$refs.saveBtn.click()"
            @keyup.esc="editOption = false"
            @keydown="touched.price_info = true"
          />
        </b-form-group>

        <b-form-group
          v-if="type == 1 && billable == '1'"
          label-for="pricing-model"
          :label="$t('Pricing model')"
        >
          <v-select
            id="pricing-model"
            v-model="newValue.pricing_model"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('Pricing model')"
            :options="pricingModelList"
            :clearable="false"
            :reduce="option => option.value"
            label="label"
            :class="emptyValue.pricing_model ? 'border-danger-for-v-select' : ''"
            @keydown="touched.pricing_model = true"
          />
        </b-form-group>

        <!-- setup price model in edition -->

        <div class="d-flex">
          <b-button
            v-if="value.price !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="editOption = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-if="value.price === ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-auto"
            variant="outline-primary"
            @click="deleteOption"
          >
            {{ $t('Delete') }}
          </b-button>
          <b-button
            ref="saveBtn"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1"
            variant="primary"
            :disabled="disableSave"
            @click="saveNewValue"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BFormGroup, BButton, VBTooltip, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import CurrencyInput from '../components/CurrencyInput'

export default {
  name: 'PriceOptionInput',
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    vSelect,
    CurrencyInput,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    linkedToPrice: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
    },
    optionIndex: {
      type: Number,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
    billable: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      editOption: this.isOpen,
      free_or_paid: this.value.price === 0 ? 'free' : 'paid',
      attachedLink: null,
      touched: {
        price: false,
        price_unit: false,
        price_info: false,
        price_custom: false,
      },
      defaultData: {
        id: -1,
        price: '',
        price_unit: '',
        price_info: '',
        price_custom: '',
        linked_elements: null,
      },
      pricing_model: null,
      newValue: null,
      priceUnitList: null,
      pricingModelList: [
        { label: this.$t('One-off payment'), value: 'one_off' },
        { label: this.$t('Monthly subscription'), value: 'subscription' },
      ],
    }
  },
  computed: {
    isCampActivity() {
      return this.$route.params.id === '2'
    },
    isEventActivity() {
      return this.$route.params.id === '3'
    },
    freePriceUnitList() {
      if (this.isEventActivity) {
        return [{ label: this.$t('Event'), value: this.$t('Event') }]
      } else {
        return [
          { label: this.$t('Free trial'), value: 'Free trial' },
          { label: this.$t('Custom'), value: '' },
        ]
      }
    },
    linkedElements() {
      return this.linkedToPrice.map(el => {
        return {
          code: el.from_time + ' - ' + el.to_time,
          label: el.from_time + ' - ' + el.to_time,
        }
      })
    },
    emptyValue() {
      const checkEmpty = {
        price: false,
        price_unit: false,
        linked_elements: false,
        price_info: this.$route.params.id == 2,
        pricing_model: null,
        billable: false,
      }
      const keys = Object.keys(this.newValue)
      for (const key of keys) {
        if (
          key === 'id' ||
          key === 'price_custom' ||
          key === 'price_unit' ||
          key === 'linked_elements' ||
          key === 'pricing_model'
        )
          continue

        if (
          (this.newValue[key] === '' || this.newValue[key].toString().match(/^ *$/) !== null) &&
          this.touched[key]
        ) {
          checkEmpty[key] = true
        }
      }
      return checkEmpty
    },
    disableSave() {
      let isEmpty = false
      let isChanged = false
      const keys = Object.keys(this.newValue)
      for (const key of keys) {
        if (key === 'id') continue
        if (
          key === 'price_info' ||
          key === 'price_custom' ||
          key === 'price_unit' ||
          key === 'linked_elements' ||
          key === 'pricing_model'
        ) {
          // price info is optional input now
          isChanged = true
          continue
        }
        if (this.newValue[key] === '' || this.newValue[key].toString().match(/^ *$/) !== null) {
          isEmpty = true
        } else if (this.newValue[key] !== this.value[key]) {
          isChanged = true
        }
      }
      return isEmpty || !isChanged
    },
  },
  watch: {
    free_or_paid(newVal) {
      if (newVal == 'free') {
        this.newValue.price = 0
        this.newValue.price_unit = this.isEventActivity ? this.$t('Event') : 'Free trial'
      } else {
        this.newValue.price = ''
        this.newValue.price_unit = this.priceUnitList[0].value
      }
    },
    'newValue.price'(val) {
      if (val === 0) this.free_or_paid = 'free'
    },
    value(newVal) {
      this.newValue.id = newVal.id
      this.newValue.price = newVal.price
      this.newValue.price_unit = newVal.price_unit
      this.newValue.price_info = newVal.price_info
      this.newValue.price_custom = newVal.price_custom
      this.newValue.pricing_model = newVal.pricing_model
      this.newValue.linked_elements = newVal.linked_elements
      this.newValue.billable = newVal.billable
    },
    '$store.state.appConfig.lang'() {
      if (this.isEventActivity) {
        this.freePriceUnitList = [{ label: this.$t('Event'), value: 'Event' }]
      } else {
        this.freePriceUnitList = [
          { label: this.$t('Free trial'), value: 'Free trial' },
          { label: this.$t('Custom'), value: '' },
        ]
      }

      switch (+this.$route.params.id) {
        case 1:
          this.priceUnitList = [
            { label: this.$t('Hour'), value: 'Hour' },
            { label: this.$t('Class'), value: 'Class' },
            { label: this.$t('Day'), value: 'Day' },
            { label: this.$t('Week'), value: 'Week' },
            { label: this.$t('Month'), value: 'Month' },
            { label: this.$t('Trimester'), value: 'Trimester' },
            { label: this.$t('Semester'), value: 'Semester' },
            { label: this.$t('Year'), value: 'Year' },
            { label: this.$t('Custom'), value: '' },
          ]
          this.pricingModelList = [
            { label: this.$t('One-off payment'), value: 'one_off' },
            { label: this.$t('Monthly subscription'), value: 'subscription' },
          ]
          break
        case 2:
          this.priceUnitList = [
            { label: this.$t('Day'), value: 'Day' },
            { label: this.$t('Week'), value: 'Week' },
            { label: this.$t('Full camp'), value: 'Full camp' },
            { label: this.$t('Custom'), value: '' },
          ]
          break
        case 3:
          this.priceUnitList = [
            { label: this.$t('Hour'), value: 'Hour' },
            { label: this.$t('Day'), value: 'Day' },
            { label: this.$t('Workshop'), value: 'Workshop' },
            { label: this.$t('Event'), value: 'Event' },
            { label: this.$t('Custom'), value: '' },
          ]
          break
      }
    },
  },
  mounted() {
    this.newValue = { ...this.defaultData }

    switch (+this.$route.params.id) {
      case 1:
        this.priceUnitList = [
          { label: this.$t('Hour'), value: 'Hour' },
          { label: this.$t('Class'), value: 'Class' },
          { label: this.$t('Day'), value: 'Day' },
          { label: this.$t('Week'), value: 'Week' },
          { label: this.$t('Month'), value: 'Month' },
          { label: this.$t('Trimester'), value: 'Trimester' },
          { label: this.$t('Semester'), value: 'Semester' },
          { label: this.$t('Year'), value: 'Year' },
          { label: this.$t('Custom'), value: '' },
        ]
        break
      case 2:
        this.priceUnitList = [
          { label: this.$t('Day'), value: 'Day' },
          { label: this.$t('Week'), value: 'Week' },
          { label: this.$t('Full camp'), value: 'Full camp' },
          { label: this.$t('Custom'), value: '' },
        ]
        break
      case 3:
        this.priceUnitList = [
          { label: this.$t('Hour'), value: 'Hour' },
          { label: this.$t('Day'), value: 'Day' },
          { label: this.$t('Workshop'), value: 'Workshop' },
          { label: this.$t('Event'), value: 'Event' },
          { label: this.$t('Birthday'), value: 'Birthday' },
          { label: this.$t('Custom'), value: '' },
        ]
        break
    }
  },
  methods: {
    openEdit() {
      this.editOption = true
      this.touched.price = false
      this.touched.price_unit = false
      this.touched.price_info = false
      this.newValue.id = this.value.id
      this.newValue.price = this.value.price
      this.newValue.price_unit = this.value.price_unit
      this.newValue.price_info = this.value.price_info
      this.newValue.pricing_model = this.value.pricing_model
      this.newValue.linked_elements = this.value.linked_elements
    },
    cancelEdit() {
      this.editOption = false
      if (this.newValue.id === -1) {
        this.$emit('deleteOption', +this.optionIndex)
      }
    },
    saveNewValue() {
      this.newValue.linked_elements = this.attachedLink
      this.$emit('optionChanged', this.newValue)
      this.editOption = false
    },
    deleteOption() {
      this.$emit('deleteOption', this.optionIndex)
    },
    placeholderText() {
      switch (+this.$route.params.id) {
        case 1:
          return this.$t('Price info placeholder-activity')
        case 2:
          return this.$t('Price info placeholder-camp')
        case 3:
          return this.$t('Price info placeholder-event')
      }
    },
  },
}
</script>

<style scoped>
.inputOption .form-control[readonly] {
  background-color: unset;
}

.inputOption .btn-icon {
  padding: 0.5rem;
}

.inputOption .btn-icon:last-of-type {
  margin-right: -0.5rem;
}

.card {
  box-shadow: 0 4px 24px 0 rgb(14 26 26 / 10%) !important;
}

.custom-form-input-for-options {
  font-weight: 600;
}

.card-label {
  min-width: 20%;
}
</style>
